import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import styled, {ThemeProvider} from 'styled-components';
import withLayout from "../layout";
import dark from "../theme/dark";
import {FormattedMessage, injectIntl} from "react-intl";
import {Heading, Paragraph} from "../components/UI/Typography.js";
import {Button} from "../components/UI/Button.js";
import Link from "../components/Link.js";
import useDimensions from "react-use-dimensions";
import notFoundMobile from "../images/404/467.jpg";
import notFoundTablet from "../images/404/760.jpg";
import notFoundDesktop from "../images/404/1920.jpg";
import SEO from "../components/SEO";

const Wrapper = styled.div`
    background: url("${props => props.bgImage}") center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    height: 100vh;
}`;

const NotFoundPage = ({intl}) => {
    const [ref, {width}] = useDimensions();
    const [bgImage, setBgImage] = useState();
    const keywords = intl.formatMessage({id: 'notFound.meta_keywords'}).split(',') || [];

    useEffect(() => {
        if (width <= 500) {
            setBgImage(notFoundMobile);
        } else if (width <= 767) {
            setBgImage(notFoundTablet);
        } else {
            setBgImage(notFoundDesktop);
        }
    }, [width])

    return <>
        <SEO title={intl.formatMessage({id: 'notFound.meta_title'})}
             description={intl.formatMessage({id: 'notFound.meta_description'})} keywords={keywords} lang={intl.locale}/>
        <div ref={ref}>
            <ThemeProvider theme={dark} colors={dark ? dark.colors : null}>
                <Wrapper bgImage={bgImage}>
                    <Header siteTitle={"kromin"} hideLangs={false}/>
                    <div style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>
                        <div>
                            <Heading level={'2'} margin={'0'}>
                                <FormattedMessage id="notFound.title"/>
                            </Heading>
                            <Heading level={'4'} margin={'0'}>
                                <FormattedMessage id="notFound.subtitle"/>
                            </Heading>
                            <Paragraph>
                                <FormattedMessage id="notFound.text"/>
                            </Paragraph>
                            <Link className="link-no-decoration text-center" to={"/"}
                                  style={{display: 'inline-flex', marginTop: '64px'}}>
                                <Button type={'white'} label={intl.formatMessage({id: "notFound.btn_message"})}/>
                            </Link>
                        </div>
                    </div>
                </Wrapper>
            </ThemeProvider>
        </div>
    </>
}

const customProps = {
    localeKey: "notFound",
};

export default withLayout(customProps)(injectIntl(NotFoundPage));
